// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beyondmoderation-mdx": () => import("./../../../src/pages/beyondmoderation.mdx" /* webpackChunkName: "component---src-pages-beyondmoderation-mdx" */),
  "component---src-pages-blog-frontend-choices-mdx": () => import("./../../../src/pages/blog/frontend-choices.mdx" /* webpackChunkName: "component---src-pages-blog-frontend-choices-mdx" */),
  "component---src-pages-blog-index-mdx": () => import("./../../../src/pages/blog/index.mdx" /* webpackChunkName: "component---src-pages-blog-index-mdx" */),
  "component---src-pages-blog-industrial-extras-mdx": () => import("./../../../src/pages/blog/industrial-extras.mdx" /* webpackChunkName: "component---src-pages-blog-industrial-extras-mdx" */),
  "component---src-pages-blog-industrial-web-design-mdx": () => import("./../../../src/pages/blog/industrial-web-design.mdx" /* webpackChunkName: "component---src-pages-blog-industrial-web-design-mdx" */),
  "component---src-pages-blog-introduction-data-viz-web-mdx": () => import("./../../../src/pages/blog/introduction-data-viz-web.mdx" /* webpackChunkName: "component---src-pages-blog-introduction-data-viz-web-mdx" */),
  "component---src-pages-blog-reflections-on-building-in-science-mdx": () => import("./../../../src/pages/blog/reflections-on-building-in-science.mdx" /* webpackChunkName: "component---src-pages-blog-reflections-on-building-in-science-mdx" */),
  "component---src-pages-explorers-mdx": () => import("./../../../src/pages/explorers.mdx" /* webpackChunkName: "component---src-pages-explorers-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-ingredients-mdx": () => import("./../../../src/pages/ingredients.mdx" /* webpackChunkName: "component---src-pages-ingredients-mdx" */),
  "component---src-pages-london-mdx": () => import("./../../../src/pages/london.mdx" /* webpackChunkName: "component---src-pages-london-mdx" */),
  "component---src-pages-ml-delib-2023-mdx": () => import("./../../../src/pages/ml-delib-2023.mdx" /* webpackChunkName: "component---src-pages-ml-delib-2023-mdx" */),
  "component---src-pages-recipes-index-mdx": () => import("./../../../src/pages/recipes/index.mdx" /* webpackChunkName: "component---src-pages-recipes-index-mdx" */),
  "component---src-pages-recipes-las-mdx": () => import("./../../../src/pages/recipes/las.mdx" /* webpackChunkName: "component---src-pages-recipes-las-mdx" */),
  "component---src-pages-recipes-oatmilk-mdx": () => import("./../../../src/pages/recipes/oatmilk.mdx" /* webpackChunkName: "component---src-pages-recipes-oatmilk-mdx" */),
  "component---src-pages-recipes-pancakes-mdx": () => import("./../../../src/pages/recipes/pancakes.mdx" /* webpackChunkName: "component---src-pages-recipes-pancakes-mdx" */),
  "component---src-pages-recipes-ragu-mdx": () => import("./../../../src/pages/recipes/ragu.mdx" /* webpackChunkName: "component---src-pages-recipes-ragu-mdx" */)
}

